import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { dismissModal, setRoutePending } from '../state/app';
import { ModalType, RedirectOptions } from '../typescript/typings';
import redirects from '../routing/redirects';

const useAuthRedirect = () => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const loggedIn = useAppSelector((state) => !!state.user.accessToken);
    const modal = useAppSelector((state) => state.app.modal);
    const redirect = redirects[router.pathname] || ({} as RedirectOptions);

    useEffect(() => {
        const handleRouteChangeStart = () => {
            dispatch(setRoutePending(true));
        };

        router.events.on('routeChangeStart', handleRouteChangeStart);

        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
        };
    }, [dispatch, router]);

    useEffect(() => {
        if (modal?.type === ModalType.Login || modal?.type === ModalType.Signup) {
            const handleRouteChangeComplete = () => {
                dispatch(dismissModal());
            };

            router.events.on('routeChangeComplete', handleRouteChangeComplete);

            return () => {
                router.events.off('routeChangeComplete', handleRouteChangeComplete);
            };
        }
    }, [dispatch, router, modal]);

    useEffect(() => {
        if (!redirect.to) {
            dispatch(setRoutePending(false));
            return;
        }

        if (loggedIn === redirect.loggedIn) {
            router.push(redirect.to);
        } else {
            dispatch(setRoutePending(false));
        }
    }, [dispatch, router, loggedIn, redirect.loggedIn, redirect.to]);
};

export default useAuthRedirect;
