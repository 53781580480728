import { PayloadAction, createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { User, UserAvatar } from '../../api-client/types';
import { UserFlag, UserFlags } from '../../base/types';
import { changeName, init, login, logout } from './sharedActions';

export interface UserState {
    instance: User;
    id: string;
    name: string;
    email: string;
    accessToken: string;
    magicPubKey: string;
    initialized: boolean;
    avatar: UserAvatar;
    flags: UserFlags;
    roomJoinsCount: number;
}

export const initialState: UserState = {
    instance: null,
    id: '',
    name: '',
    email: '',
    accessToken: '',
    magicPubKey: '',
    initialized: false,
    avatar: null,
    flags: null,
    roomJoinsCount: null,
};

export const extraReducers = (
    builder: ActionReducerMapBuilder<UserState>
): ActionReducerMapBuilder<UserState> => {
    builder
        .addCase(init, (state, action: PayloadAction<User | null>) => ({
            ...state,
            ...(action.payload || {}),
            instance: action.payload,
            initialized: true,
        }))
        .addCase(login, (state, action) => {
            const { user, accessToken } = action.payload;

            return {
                ...state,
                ...user,
                accessToken,
                instance: user,
            };
        })
        .addCase(changeName, (state, action) => {
            state.name = action.payload;
        })
        .addCase(logout, (state) => {
            return {
                ...initialState,
                initialized: true,
                email: state.email,
            };
        });

    return builder;
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAccessToken(state, action: PayloadAction<string>) {
            state.accessToken = action.payload;
        },
        setFromStorage(state, action: PayloadAction<Partial<UserState>>) {
            return {
                ...state,
                ...action.payload,
            };
        },
        setMagicPubKey(state, action: PayloadAction<UserState['magicPubKey']>) {
            state.magicPubKey = action.payload;
        },
        setUserFlags(state, action: PayloadAction<UserFlags>) {
            state.flags = action.payload;
        },
        updateUserFlags(state, action: PayloadAction<Partial<UserFlags>>) {
            state.flags = {
                ...state.flags,
                ...action.payload,
            };
        },
        updateRoomJoinsCount(state, action: PayloadAction<number>) {
            state.roomJoinsCount = action.payload;
        },
    },
    extraReducers,
});

export const {
    setAccessToken,
    setMagicPubKey,
    setFromStorage,
    setUserFlags,
    updateUserFlags,
    updateRoomJoinsCount,
} = userSlice.actions;

export default userSlice.reducer;
