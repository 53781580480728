import { PagePermissions, RedirectOptions } from '../typescript/typings';

const redirects: { [path: string]: RedirectOptions } = {
    '/': { loggedIn: true, to: '/home' },
    '/invite': { loggedIn: true, to: '/home' },
    '/home': { loggedIn: false, to: '/' },
    '/support': { permissions: [PagePermissions.Staff], loggedIn: false, to: '/' },
};

export default redirects;
