import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaginationState, StreamAction } from '../types';
import { UserResponse, MessageResponse, ChannelMemberResponse } from 'stream-chat';
import { WritableDraft } from 'immer/dist/internal';
import { User } from '../../api-client/types';
import { ChannelType, GiphyPreview } from '../../text-chat/types';

const MAX_MESSAGE_NOTIFICATIONS = 3;

export interface MessageListState extends PaginationState {
    pinned: MessageResponse['id'][];
    pinnedHideTime: number;
    unreadCount: number;
    banned: { [id: string]: true };
    thread: PaginationState & {
        parent: MessageResponse;
    };
    giphyPreview: GiphyPreview;
}

type MessageLists = {
    [key in ChannelType]: MessageListState;
};

export interface MessagesState extends MessageLists {
    popout: boolean;
    open: boolean;
    pane: ChannelType;
    notificationsEnabled: boolean;
    notifications: MessageResponse[];
}

const initialMessageListState = {
    loading: true,
    hasMore: true,
    allIds: [],
    byId: {},
    pinned: [],
    pinnedHideTime: null,
    unreadCount: 0,
    banned: {},
    lastPage: [],
    thread: {
        hasMore: true,
        parent: null,
        loading: true,
        allIds: [],
        byId: {},
        lastPage: [],
    },
    giphyPreview: null,
};

export const initialState: MessagesState = {
    popout: false,
    open: true,
    pane: ChannelType.Room,
    notificationsEnabled: true,
    notifications: [],
    [ChannelType.Room]: initialMessageListState,
    [ChannelType.Support]: initialMessageListState,
    [ChannelType.Mod]: initialMessageListState,
};

const MessagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setChatPane(state, action: PayloadAction<ChannelType>) {
            state.pane = action.payload;
            state[action.payload].unreadCount = 0;
        },
        togglePopoutChat(state, action: PayloadAction<boolean>) {
            if (!state.popout) {
                state.notifications = [];
            }

            state.popout = action.payload;
        },
        toggleLoading(state, action: StreamAction<{ loading: boolean }>) {
            const { type, loading } = action.payload;
            state[type].loading = loading;
        },
        toggleMessageList(state: MessagesState, action: PayloadAction<boolean | undefined>) {
            if (typeof action.payload !== 'undefined' && action.payload === state.open) {
                return;
            }

            const open = typeof action.payload === 'undefined' ? !state.open : action.payload;

            if (!state.open) {
                state.notifications = [];
            }

            state.open = open;
            state[state.pane].unreadCount = 0;
        },
        toggleMessageNotifications(state: MessagesState) {
            state.notificationsEnabled = !state.notificationsEnabled;
        },
        updatePinned(state, action: StreamAction<{ pinned: MessageResponse[] }>) {
            const { type, pinned } = action.payload;
            pinned.forEach((message) => {
                state[type].byId[message.id] = message;
            });

            state[type].pinned = pinned.map((message) => message.id);
        },
        toggleChatUserBanned(state, action: StreamAction<{ user: UserResponse; banned: boolean }>) {
            const { type, user, banned } = action.payload;
            updateUserBanned(type, user.id, banned, state);
        },
        updateChannelMembers(state, action: StreamAction<{ members: ChannelMemberResponse[] }>) {
            const { type, members } = action.payload;
            members.forEach((channelMember) => {
                updateUserBanned(type, channelMember.user_id, channelMember.banned, state);
            });
        },
        endReached(state, action: StreamAction<{}>) {
            state[action.payload.type].hasMore = false;
            state[action.payload.type].loading = false;
        },
        receiveChatMessage(
            state: MessagesState,
            action: StreamAction<{
                message: MessageResponse;
                outbound: boolean;
                userID: string;
                prepend?: boolean;
            }>
        ) {
            const { type, message, outbound, userID, prepend = false } = action.payload;
            const { id, parent_id, pinned } = message;
            if (!state.popout && !outbound && (!state.open || state.pane !== type)) {
                state[type].unreadCount += 1;
            }

            // Message replies
            if (parent_id) {
                const parent = state[type].byId[parent_id];

                if (parent) {
                    if (!parent.thread_participants?.some((p) => p.id === message.user?.id)) {
                        parent.thread_participants = [
                            ...(parent.thread_participants || []),
                            message.user,
                        ];
                    }

                    if (
                        parent.thread_participants?.some((p) => p.id === userID) ||
                        (message.customData as any)?.mentioned_users?.some(
                            (user: Partial<User>) => user.id === userID
                        )
                    ) {
                        addChatNotification(message, state);
                    }
                }

                if (state[type].thread.parent && state[type].thread.parent.id === parent_id) {
                    if (prepend) {
                        state[type].thread.allIds.unshift(id);
                    } else {
                        state[type].thread.allIds.push(id);
                    }
                }
            }

            if (pinned && !state[type].pinned.includes(id)) {
                state[type].pinned.push(id);
            }

            if (!state[type].byId[id]) {
                if (prepend) {
                    state[type].allIds.unshift(id);
                } else {
                    state[type].allIds.push(id);
                }
            }

            state[type].byId[id] = message;

            addChatNotification(message, state);
        },
        receiveEphemeralMessage(
            state: MessagesState,
            action: StreamAction<{
                message: MessageResponse;
                prepend?: boolean;
            }>
        ) {
            const { type, message, prepend = false } = action.payload;
            const { id, parent_id } = message;

            // Message replies
            if (
                parent_id &&
                state[type].thread.parent &&
                state[type].thread.parent.id === parent_id
            ) {
                if (prepend) {
                    state[type].thread.allIds.unshift(id);
                } else {
                    state[type].thread.allIds.push(id);
                }
            }

            if (!state[type].byId[id]) {
                if (prepend) {
                    state[type].allIds.unshift(id);
                } else {
                    state[type].allIds.push(id);
                }
            }

            state[type].byId[id] = message;
        },
        updateChatMessageReactions(state, action: StreamAction<{ message: MessageResponse }>) {
            const { type, message } = action.payload;

            const { id, own_reactions, latest_reactions, reaction_counts, reaction_scores } =
                message;

            const existing = state[type].byId[id];
            if (existing) {
                state[type].byId[id] = {
                    ...existing,
                    own_reactions,
                    latest_reactions,
                    reaction_counts,
                    reaction_scores,
                };
            } else {
                state[type].byId[id] = message;
            }
        },
        updateChatMessage(
            state: MessagesState,
            action: StreamAction<{ message: MessageResponse }>
        ) {
            const { type, message } = action.payload;
            const { id, pinned } = message;

            // own_reactions are sometimes empty
            // @see https://github.com/GetStream/stream-chat-js/issues/823
            const { own_reactions, ...updates } = message;

            const existing = state[type].byId[id];
            state[type].byId[id] = existing ? { ...existing, ...updates } : message;

            if (pinned && !state[type].pinned.includes(id)) {
                // Add to pinned
                state[type].pinned.push(id);
            } else if (!pinned && state[type].pinned.includes(id)) {
                // Remove from pinned
                state[type].pinned = state[type].pinned.filter((pinnedId) => pinnedId !== id);
            }
        },
        deleteChatMessage(state, action: StreamAction<{ message: MessageResponse }>) {
            const { type, message } = action.payload;
            state[type].pinned = state[type].pinned.filter((pinnedId) => pinnedId !== message.id);
            delete state[type].byId[message.id];
            state[type].allIds = state[type].allIds.filter((id) => id !== message.id);
            state[type].lastPage = state[type].lastPage.filter(
                (message) => message.id !== message.id
            );

            // Remove from thread
            state[type].thread.allIds = state[type].thread.allIds.filter((id) => id !== message.id);

            // Delete entire thread if parent
            if (state[type].thread.parent?.id === message.id) {
                state[type].thread = {
                    ...initialMessageListState.thread,
                };
            }
        },
        deleteEphemeralMessage(state, action: StreamAction<{ id: string }>) {
            const { type, id } = action.payload;
            delete state[type].byId[id];
            state[type].allIds = state[type].allIds.filter((messageId) => messageId !== id);
        },
        appendMessages(state, action: StreamAction<{ messages: MessageResponse[] }>) {
            const { type, messages } = action.payload;
            addMessages(type, messages, state, false);
        },
        prependMessages(state, action: StreamAction<{ messages: MessageResponse[] }>) {
            const { type, messages } = action.payload;
            addMessages(type, messages, state);
        },
        dismissMessageNotification(state, action: PayloadAction<MessageResponse['id']>) {
            const index = state.notifications.findIndex((message) => message.id === action.payload);
            if (index !== -1) {
                state.notifications.splice(index, 1);
            }
        },
        resetMessages(state) {
            return {
                ...initialState,
                notificationsEnabled: state.notificationsEnabled,
            };
        },
        resetMessagePane(state, action: PayloadAction<ChannelType>) {
            state[action.payload] = {
                ...initialMessageListState,
            };
        },
        openThread(state, action: StreamAction<{ parent: MessageResponse }>) {
            const { type, parent } = action.payload;
            state[type].thread = {
                ...initialMessageListState.thread,
                parent,
            };
        },
        closeThread(state, action: StreamAction<{}>) {
            state[action.payload.type].thread = {
                ...initialMessageListState.thread,
            };
        },
        toggleThreadLoading(state, action: StreamAction<{ loading: boolean }>) {
            const { type, loading } = action.payload;
            state[type].thread.loading = loading;
        },
        appendThreadMessages(state, action: StreamAction<{ messages: MessageResponse[] }>) {
            const { type, messages } = action.payload;
            addThreadMessages(type, messages, state, false);
        },
        prependThreadMessages(state, action: StreamAction<{ messages: MessageResponse[] }>) {
            const { type, messages } = action.payload;
            addThreadMessages(type, messages, state);
        },
        threadEndReached(state, action: StreamAction<{}>) {
            state[action.payload.type].thread.hasMore = false;
            state[action.payload.type].thread.loading = false;
        },
        setGiphyPreview(state, action: StreamAction<{ preview: GiphyPreview }>) {
            const { type, preview } = action.payload;
            state[type].giphyPreview = preview;
        },
        updatePinnedHideTime(state, action: StreamAction<{ time: number }>) {
            const { type, time } = action.payload;
            state[type].pinnedHideTime = time;
        },
    },
});

function updateUserBanned(
    type: ChannelType,
    userID: string,
    banned: boolean,
    state: WritableDraft<MessagesState>
) {
    if (banned) {
        state[type].banned[userID] = true;
    } else {
        delete state[type].banned[userID];
    }
}

function addMessages(
    type: ChannelType,
    messages: MessageResponse[],
    state: WritableDraft<MessagesState>,
    prepend = true
) {
    state[type].lastPage = messages;
    messages.forEach((message) => {
        if (prepend) {
            state[type].allIds.unshift(message.id);
        } else {
            state[type].allIds.push(message.id);
        }
        state[type].byId[message.id] = message;

        updateUserBanned(type, message.user?.id, message.user?.banned, state);
    });

    state[type].loading = false;
}

function addThreadMessages(
    type: ChannelType,
    messages: MessageResponse[],
    state: WritableDraft<MessagesState>,
    prepend = true
) {
    const parentId = messages[0] && messages[0].parent_id;

    if (!parentId || !state[type].thread.parent) {
        state[type].thread.loading = false;
        return;
    }

    if (parentId !== state[type].thread.parent.id) {
        return;
    }

    state[type].thread.lastPage = messages;
    messages.forEach((message) => {
        if (prepend) {
            state[type].thread.allIds.unshift(message.id);
        } else {
            state[type].thread.allIds.push(message.id);
        }

        // Add to main message map
        state[type].byId[message.id] = message;

        updateUserBanned(type, message.user?.id, message.user?.banned, state);
    });

    state[type].thread.loading = false;
}

function addChatNotification(message: MessageResponse, state: WritableDraft<MessagesState>) {
    const { id } = message;

    if (
        !state.open &&
        !state.popout &&
        state.notificationsEnabled &&
        !state.notifications.some((message) => message.id === id)
    ) {
        state.notifications = [
            message,
            ...state.notifications.slice(0, MAX_MESSAGE_NOTIFICATIONS - 1),
        ];
    }
}

export const {
    setChatPane,
    toggleLoading,
    togglePopoutChat,
    toggleMessageList,
    toggleMessageNotifications,
    endReached,
    receiveChatMessage,
    receiveEphemeralMessage,
    updateChatMessage,
    updateChatMessageReactions,
    updateChannelMembers,
    dismissMessageNotification,
    resetMessages,
    resetMessagePane,
    appendMessages,
    prependMessages,
    openThread,
    closeThread,
    toggleThreadLoading,
    threadEndReached,
    appendThreadMessages,
    prependThreadMessages,
    updatePinned,
    deleteChatMessage,
    deleteEphemeralMessage,
    toggleChatUserBanned,
    setGiphyPreview,
    updatePinnedHideTime,
} = MessagesSlice.actions;

export default MessagesSlice.reducer;
